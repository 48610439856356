import React from 'react';
import ListSubheader, {
  ListSubheaderProps,
} from '@material-ui/core/ListSubheader';
import { Divider, makeStyles, StandardProps } from '@material-ui/core';

const MenuListSubheader: React.FunctionComponent<
  MenuListSubheaderProps
> = props => {
  const classes = useStyles(props);
  const { classes: _classes, ...rest } = props;
  return (
    <div className={classes.root}>
      <Divider classes={{ root: classes.divider }} />
      <ListSubheader classes={{ root: classes.listSubheader }} {...rest} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75),
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  listSubheader: {
    ...theme.typography.h6,
    paddingLeft: 0,
    marginBottom: theme.spacing(1.875),
  },
}));

export type MenuListSubheaderProps = StandardProps<
  ListSubheaderProps,
  MenuListSubheaderClassKey
>;

export type MenuListSubheaderClassKey = 'root' | 'divider' | 'listSubheader';

export default MenuListSubheader;
