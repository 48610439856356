import React from 'react';
import clsx from 'clsx';
import { pxToRem } from '../utils';
import { felix } from '../colors';
import { StandardProps, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconLookup, IconName } from '@fortawesome/fontawesome-svg-core';

/**
 * Component to abstract the creation of icons from font-awesome
 */
const Icon: React.FunctionComponent<IconProps> = props => {
  const {
    name,
    size = 16,
    color = felix(),
    weight = 'regular',
    className,
    ref,
    ...other
  } = props;

  const classes = useStyles(props);
  const iconStyle = {
    fontSize: pxToRem(size),
    lineHeight: pxToRem(size),
    color: color,
  };

  const iconLookup: IconLookup = {
    prefix: weightToFaClass(weight),
    iconName: name,
  };

  return (
    <span
      style={iconStyle}
      className={clsx(classes.root, className)}
      {...other}
    >
      <FontAwesomeIcon icon={iconLookup} />
    </span>
  );
};

const weightToFaClass = (weight: IconWeight): FaIconClass => {
  switch (weight) {
    case 'solid':
      return 'fas';
    case 'regular':
      return 'far';
    case 'light':
      return 'fal';
  }
};

const useStyles = makeStyles(_theme => ({
  root: {},
}));

interface OwnProps {
  /** The icon name from the font-awesome library */
  name: IconName;
  /** The size, in pixels, to render the icon */
  size?: number;
  /** The color for styling the icon */
  color?: string;
  /** The weight attribute of the icon */
  weight?: IconWeight;
}

export type IconProps = OwnProps &
  StandardProps<React.HTMLAttributes<HTMLSpanElement>, IconClassKey>;

export type IconClassKey = 'root';

export type IconWeight = 'solid' | 'regular' | 'light';
export type FaIconClass = 'fas' | 'far' | 'fal';

export default Icon;
