import { felix, bugs } from '../colors';
import { fontFamilyRoboto, fontFamilyNoeDisplayBlack } from '../fonts';
import { pxToRem } from '../utils';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

const typographyOverrides: TypographyOptions = {
  fontFamily: fontFamilyRoboto,
  h1: {
    fontFamily: fontFamilyNoeDisplayBlack,
    fontSize: pxToRem(36),
    lineHeight: pxToRem(42),
    textTransform: 'capitalize',
    color: felix(),
  },
  h2: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(27),
    lineHeight: pxToRem(35),
    fontWeight: 300,
    color: felix(),
  },
  h3: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(17),
    lineHeight: pxToRem(23),
    color: felix(),
  },
  h4: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(23),
    fontWeight: 'bold',
    color: felix(),
  },
  h5: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(20),
    fontWeight: 500,
    color: felix(),
  },
  h6: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(11),
    lineHeight: pxToRem(15),
    fontWeight: 500,
    letterSpacing: '0.075em',
    textTransform: 'uppercase',
    color: bugs(),
  },
  subtitle1: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(17),
    color: felix(0.8),
    letterSpacing: '-0.016em',
  },
  subtitle2: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(17),
    fontWeight: 'bold',
    color: felix(),
    letterSpacing: '-0.016em',
  },
  body1: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    color: felix(),
    letterSpacing: '0.003em',
  },
  body2: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    color: bugs(),
    letterSpacing: '0.003em',
  },
  caption: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(13),
    lineHeight: pxToRem(16),
    color: bugs(),
  },
  button: {
    fontFamily: fontFamilyRoboto,
    fontSize: pxToRem(14),
    color: felix(),
    textTransform: 'none',
  },
};

export default typographyOverrides;
