import React from 'react';
import { ListItem, makeStyles } from '@material-ui/core';
import { ListItemProps } from '@material-ui/core/ListItem';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const MenuListItem: OverridableComponent<{
  props: MenuListItemProps;
  defaultComponent: 'li';
  classKey: MenuListItemClassKey;
}> = (props: MenuListItemProps) => {
  const classes = useStyles(props);

  return (
    <ListItem
      classes={{ root: classes.root, selected: classes.selected }}
      button
      component="li"
      {...props}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
    paddingLeft: theme.spacing(2.75),
    '&:hover,&$selected,&$selected:hover': {
      backgroundColor: '#F6F6F6',
    },
  },
  selected: {
    fontWeight: 'bold',
  },
}));

export type MenuListItemProps = ListItemProps<'li', { button?: true }>;

export type MenuListItemClassKey = 'root' | 'selected';

export default MenuListItem;
