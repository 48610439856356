import { StyleRules } from '@material-ui/core/styles';
import { bugs, felix } from '../colors';
import { pxToRem } from '../utils';
import { InputLabelClassKey } from '@material-ui/core/InputLabel';

const inputLabelOverrides: Partial<StyleRules<InputLabelClassKey>> = {
  root: {
    fontSize: pxToRem(15),
    color: bugs(),
    '&$focused': {
      color: felix(),
    },
  },
  focused: {},
  shrink: {
    fontSize: pxToRem(12),
    transform: 'scale(1)',
  },
};

export default inputLabelOverrides;
