import React from 'react';
import MuiTablePaginationActions, {
  TablePaginationActionsProps,
} from '@material-ui/core/TablePagination/TablePaginationActions';
import { makeStyles } from '@material-ui/core';
import { astro, bugs } from '../colors';
import { pxToRem } from '../utils';

const TablePaginationActions: React.FunctionComponent<
  TablePaginationActionsProps
> = props => {
  const classes = useStyles();

  return (
    <MuiTablePaginationActions
      {...props}
      nextIconButtonProps={{
        disableRipple: true,
        classes: {
          root: classes.root,
          label: classes.label,
          disabled: classes.disabled,
        },
      }}
      backIconButtonProps={{
        disableRipple: true,
        classes: {
          root: classes.root,
          label: classes.label,
          disabled: classes.disabled,
        },
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    borderRadius: '5px',
    marginLeft: theme.spacing(1),
    '&$disabled': {
      backgroundColor: astro(0.33),
    },
  },
  label: {
    border: `1px solid ${astro()}`,
    borderRadius: '5px',
    padding: pxToRem(5),
    '& svg': {
      fontSize: pxToRem(22),
      color: bugs(),
    },
  },
  disabled: {
    '& $label': {
      border: 0,
      padding: pxToRem(6),
    },
    '& $label svg': {
      color: astro(),
    },
  },
  actionsRoot: {
    marginLeft: theme.spacing(2),
  },
}));

export default TablePaginationActions;
