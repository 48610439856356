import { TabClassKey } from '@material-ui/core/Tab';
import { StyleRules } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { pxToRem } from '../utils';
import { livly } from '../colors';

const breakpoints = createBreakpoints({});

export const MuiTabStyleOverrides: Partial<StyleRules<TabClassKey>> = {
  root: {
    textTransform: 'none',
    minWidth: 'auto',
    fontWeight: 'normal',
    marginRight: 4,
    fontSize: pxToRem(14),
    minHeight: 30,
    padding: pxToRem(6),
    [breakpoints.up('md')]: {
      minWidth: 'auto',
      fontSize: pxToRem(14),
    },
    '&:hover': {
      color: livly(),
      opacity: 1,
    },
    '&$selected': {
      color: livly(),
      fontWeight: 'normal',
    },
    '&:focus': {
      color: livly(),
    },
  },
  selected: {},
};
