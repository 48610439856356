import React from 'react';
import {
  StandardProps,
  makeStyles,
  Typography,
  Link,
  Divider,
} from '@material-ui/core';
import Icon from '../Icon';
import { livly } from '../colors';
import { LinkProps } from '@material-ui/core/Link';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const MenuBackButton: OverridableComponent<{
  props: MenuBackButtonProps;
  defaultComponent: 'a';
  classKey: MenuBackButtonClassKey;
}> = (props: MenuBackButtonProps) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Link className={classes.link} {...props}>
        <div className={classes.flexContainer}>
          <Icon
            name="arrow-circle-left"
            weight="solid"
            size={22}
            color={livly()}
          />
          <Typography className={classes.backText} variant="body1">
            {props.children}
          </Typography>
        </div>
      </Link>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75),
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    padding: 0,
  },
  backText: {
    marginLeft: theme.spacing(1.25),
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
}));

interface OwnProps {}

export type MenuBackButtonProps = OwnProps &
  StandardProps<LinkProps, MenuBackButtonClassKey>;

export type MenuBackButtonClassKey =
  | 'root'
  | 'link'
  | 'flexContainer'
  | 'divider'
  | 'backButton'
  | 'backText';

export default MenuBackButton;
