import { StyleRules } from '@material-ui/core/styles';
import { FormHelperTextClassKey } from '@material-ui/core/FormHelperText';
import { bugs } from '../colors';
import { pxToRem } from '../utils';

const formHelperTextOverrides: Partial<StyleRules<FormHelperTextClassKey>> = {
  root: {
    color: bugs(),
    fontSize: pxToRem(12),
  },
};

export default formHelperTextOverrides;
