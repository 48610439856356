import React from 'react';
import { Avatar, Typography } from '@material-ui/core';
import Box, { BoxProps } from '@material-ui/core/Box';
import { tomcat, felix } from '../colors';
import Icon from '../Icon';
import { IconProps } from '../Icon/Icon';

const AvatarIcon = ({ name = 'user', ...props }: Partial<IconProps>) => (
  <Icon name={name} {...props} />
);

const AvatarDetails = ({
  avatarURI,
  avatarProps,
  primary: primaryProp,
  primaryTypographyProps,
  secondary: secondaryProp,
  secondaryTypographyProps,
  children,
  initials,
  defaultIconProps,
}: Props) => {
  let primary = primaryProp !== null ? primaryProp : children;
  //@ts-ignore
  if (primary !== null && primary.type !== Typography) {
    primary = (
      <Typography variant="h4" {...primaryTypographyProps}>
        {primary}
      </Typography>
    );
  }

  let secondary = secondaryProp;
  //@ts-ignore
  if (secondary !== null && secondary.type !== Typography) {
    secondary = (
      <Typography variant="body2" {...secondaryTypographyProps}>
        {secondary}
      </Typography>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Box
        bgcolor={tomcat()}
        color={felix()}
        height={50}
        width={50}
        {...avatarProps}
        component={props => (
          <Avatar src={avatarURI} {...props}>
            {initials ? (
              initials
            ) : (
              <AvatarIcon size={18} weight="solid" {...defaultIconProps} />
            )}
          </Avatar>
        )}
      />
      <Box ml={1.75} textAlign="left">
        {primary}
        {secondary}
      </Box>
    </Box>
  );
};

export interface Props {
  avatarURI?: string;
  avatarProps?: BoxProps;
  primary: React.ReactNode;
  primaryTypographyProps?: object;
  secondary?: React.ReactNode;
  secondaryTypographyProps?: object;
  children?: React.ReactNode;
  initials?: string;
  defaultIconProps?: Partial<IconProps>;
}

export default AvatarDetails;
