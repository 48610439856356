import { StyleRules } from '@material-ui/core/styles';
import { InputClassKey } from '@material-ui/core/Input';
import { felix, bugs } from '../colors';
import { pxToRem } from '../utils';

const inputOverrides: Partial<StyleRules<InputClassKey>> = {
  root: {
    fontSize: pxToRem(15),
  },
  input: {
    '&:placeholder': {
      color: bugs(),
    },
  },
  underline: {
    '&:after': {
      borderBottom: `2px solid ${felix()}`,
    },
  },
};

export default inputOverrides;
