import {
  TablePaginationProps,
  TablePaginationClassKey,
} from '@material-ui/core/TablePagination';
import TablePaginationActions from './TablePaginationActions';
import { StyleRules } from '@material-ui/core/styles';
import { pxToRem } from '../utils';
import typography from '../Typography';

export const MuiTablePaginationPropsOverrides: Partial<TablePaginationProps> = {
  ActionsComponent: TablePaginationActions,
};

export const MuiTablePaginationStyleOverrides: Partial<
  StyleRules<TablePaginationClassKey>
> = {
  actions: {
    marginLeft: pxToRem(8),
  },
  caption: {
    ...typography.body1,
  },
};
