import React from 'react';
import clsx from 'clsx';
import { InputBase, makeStyles, StandardProps } from '@material-ui/core';
import { InputBaseProps } from '@material-ui/core/InputBase';
import Icon from '../Icon';
import { bugs } from '../colors';
import { pxToRem } from '../utils';
import { IconProps } from '../Icon/Icon';

/**
 * A specialized text field styled with a rounded border to be used for search boxes
 */
const SearchTextField: React.FunctionComponent<
  SearchTextFieldProps
> = props => {
  const { className, iconProps, ...inputProps } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.root, className)}>
      <InputBase
        classes={{ input: classes.input }}
        startAdornment={
          <Icon
            className={classes.icon}
            name="search"
            color={bugs()}
            size={14}
            {...iconProps}
          />
        }
        {...inputProps}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    border: `1px solid ${bugs(0.6)}`,
    borderRadius: pxToRem(5),
    paddingLeft: theme.spacing(1.75),
    paddingRight: theme.spacing(1.75),
  },
  input: {
    marginLeft: theme.spacing(1.5),
    paddingTop: theme.spacing(1.125),
    paddingBottom: theme.spacing(1.125),
    height: '1rem',
    ...theme.typography.body1,
    '&::placeholder': {
      color: bugs(),
      opacity: 1,
    },
  },
  icon: {},
}));

interface OwnProps {
  /** Additional properties to pass to the icon component */
  iconProps?: Partial<IconProps>;
}

export type SearchTextFieldProps = OwnProps &
  StandardProps<InputBaseProps, SearchTextFieldClassKey>;

export type SearchTextFieldClassKey = 'root' | 'input' | 'icon';

export default SearchTextField;
