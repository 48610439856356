import { felix, spaceGhost, astro, livly, sonic } from '../colors';
import { StyleRules } from '@material-ui/core/styles';
import { ButtonClassKey } from '@material-ui/core/Button';

const buttonOverrides: Partial<StyleRules<ButtonClassKey>> = {
  contained: {
    backgroundColor: felix(),
    color: spaceGhost(),
    boxShadow: 'none',
    '&:$hover': {
      backgroundColor: 'rgba(28,30,30)',
    },
    '&$disabled': {
      backgroundColor: astro(),
      color: spaceGhost(),
    },
  },
  containedPrimary: {
    backgroundColor: livly(),
    color: spaceGhost(),
    '&:$hover': {
      backgroundColor: 'rgba(255,122,112)',
    },
  },
  containedSecondary: {
    backgroundColor: sonic(),
    color: spaceGhost(),
    '&:$hover': {
      backgroundColor: 'rgba(68,121,235)',
    },
  },
  disabled: {},
};

export default buttonOverrides;
