import { TabsClassKey } from '@material-ui/core/Tabs';
import { StyleRules } from '@material-ui/core/styles';
import { livly } from '../colors';

export const MuiTabsStyleOverrides: Partial<StyleRules<TabsClassKey>> = {
  root: {
    minHeight: 'auto',
  },
  indicator: {
    backgroundColor: livly(),
  },
};
