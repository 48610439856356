import React from 'react';
import { Paper, StandardProps, makeStyles } from '@material-ui/core';
import { PaperProps } from '@material-ui/core/Paper';
import { bugs } from '../colors';

const Menu: React.FunctionComponent<MenuProps> = props => {
  const classes = useStyles(props);
  const { children, classes: _classes, ...rest } = props;

  return (
    <Paper square classes={{ root: classes.root }} {...rest}>
      {children}
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1.5),
    boxShadow: 'none',
    borderRight: `1px solid ${bugs(0.6)}`,
  },
}));

interface OwnProps {}

export type MenuProps = OwnProps & StandardProps<PaperProps, MenuClassKey>;

export type MenuClassKey = 'root';

export default Menu;
