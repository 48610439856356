import { createMuiTheme } from '@material-ui/core/styles';

import MuiButtonOverrides from './Button';
import MuiInputOverrides from './Input';
import MuiFormLabelOverrides from './InputLabel';
import MuiFormHelperTextOverrides from './FormHelperText';
import MuiTypographyOverrides from './Typography';
import MuiPaletteOverrides from './palette';
import {
  MuiTablePaginationPropsOverrides,
  MuiTablePaginationStyleOverrides,
} from './TablePagination';
import { defaultsDeep } from 'lodash';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { MuiTabsStyleOverrides } from './Tabs';
import { MuiTabStyleOverrides } from './Tabs/Tab';

const theme: ThemeOptions = {
  palette: MuiPaletteOverrides,
  typography: MuiTypographyOverrides,
  overrides: {
    MuiButton: MuiButtonOverrides,
    MuiInput: MuiInputOverrides,
    MuiInputLabel: MuiFormLabelOverrides,
    MuiFormHelperText: MuiFormHelperTextOverrides,
    MuiTablePagination: MuiTablePaginationStyleOverrides,
    MuiTabs: MuiTabsStyleOverrides,
    MuiTab: MuiTabStyleOverrides,
  },
  props: {
    MuiTablePagination: MuiTablePaginationPropsOverrides,
  },
};

export default function createLivlyTheme(mixins: ThemeOptions = {}) {
  const themeOptions: ThemeOptions = defaultsDeep({}, mixins, theme);
  return createMuiTheme(themeOptions);
}
