import React from 'react';
import { List, StandardProps, makeStyles } from '@material-ui/core';
import { ListProps } from '@material-ui/core/List';

const MenuList: React.FunctionComponent<MenuListProps> = props => {
  const { children, ...listProps } = props;

  const classes = useStyles(props);

  return (
    <List classes={{ root: classes.root }} {...listProps}>
      {children}
    </List>
  );
};

const useStyles = makeStyles(_theme => ({
  root: {}
}));

interface OwnProps {}

export type MenuListProps = OwnProps &
  StandardProps<ListProps, MenuListClassKey>;

export type MenuListClassKey = 'root';

export default MenuList;
