import React from 'react';
import { StandardProps, makeStyles, Typography } from '@material-ui/core';

const MenuHeader: React.FunctionComponent<MenuHeaderProps> = props => {
  const classes = useStyles(props);

  const { header, subheader, ref: _ref, ...rest } = props;

  return (
    <div className={classes.root} {...rest}>
      {header && (
        <Typography variant="h4" className={classes.overflow}>
          {header}
        </Typography>
      )}
      {subheader && (
        <Typography variant="body2" className={classes.overflow}>
          {subheader}
        </Typography>
      )}
      {props.children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2.75),
    paddingRight: theme.spacing(2.75),
  },
  overflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

interface OwnProps {
  header?: string;
  subheader?: string;
}

export type MenuHeaderProps = OwnProps &
  StandardProps<React.HTMLAttributes<HTMLDivElement>, MenuHeaderClassKey>;

export type MenuHeaderClassKey = 'root' | 'overflow';

export default MenuHeader;
