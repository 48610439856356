import React from 'react';
import {
  Paper,
  Toolbar,
  Typography,
  makeStyles,
  StandardProps,
} from '@material-ui/core';
import { ToolbarProps } from '@material-ui/core/Toolbar';
import { PaperProps } from '@material-ui/core/Paper';
import { bugs } from '../colors';

/**
 * Component that implements the styling of the main application bar
 */
const AppBar: React.FunctionComponent<AppBarProps> = props => {
  const { heading, subheading, toolbarProps, children } = props;

  const classes = useStyles(props);

  return (
    <Paper square classes={{ root: classes.root }}>
      <Toolbar {...toolbarProps} classes={{ root: classes.toolbar }}>
        <div className={classes.titleBlock}>
          {buildHeading(heading)}
          {buildSubheading(subheading)}
        </div>
        <div>{children}</div>
      </Toolbar>
    </Paper>
  );
};

const useStyles = makeStyles(_theme => ({
  root: {
    boxShadow: 'none',
    borderBottom: `1px solid ${bugs(0.6)}`,
  },
  titleBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

const buildHeading = (heading: string | React.ReactElement | undefined) => {
  if (typeof heading === 'string') {
    return <Typography variant="h4">{heading}</Typography>;
  } else {
    return heading;
  }
};

const buildSubheading = (
  subheading: string | React.ReactElement | undefined
) => {
  if (typeof subheading === 'string') {
    return <Typography variant="caption">{subheading}</Typography>;
  } else {
    return subheading;
  }
};

interface OwnProps {
  /** The main heading for the app bar */
  heading?: string | React.ReactElement;
  /** The sub heading for the app bar */
  subheading?: string | React.ReactElement;
  /** Additional props to pass down to the toolbar component */
  toolbarProps?: ToolbarProps;
}

export type AppBarProps = OwnProps & StandardProps<PaperProps, AppBarClassKey>;

export type AppBarClassKey = 'root' | 'toolbar' | 'titleBlock';

export default AppBar;
