import { livly, sonic, koolAid, bugs } from '../colors';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const paletteOverrides: PaletteOptions = {
  primary: {
    main: livly(),
  },
  secondary: {
    main: sonic(),
  },
  error: {
    main: koolAid(),
  },
  divider: bugs(0.6),
};

export default paletteOverrides;
